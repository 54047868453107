<template>
  <div class="wysiwyg" :class="{ error }">
    <quill-editor
      ref="myQuillEditor"
      v-model="text"
      :options="editorOption"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
    />
    <div class="error-msg" v-if="error">
      <div class="error-msg-inner">
        <font-awesome-icon class="icon" icon="exclamation-triangle" />
        <template v-if="typeof error === 'string'">{{ error }}</template>
        <template v-else>{{ label }} requires a valid input.</template>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.ql-snow .ql-stroke,
.ql-snow .ql-stroke.ql-fill {
  stroke: var(--text);
}
.ql-snow .ql-fill {
  fill: var(--text);
}
.ql-snow .ql-picker {
  color: var(--text);
}
.ql-toolbar.ql-snow + .ql-container.ql-snow,
.ql-toolbar.ql-snow {
  border: 0;
  background: var(--input-background);
  box-shadow: 1px 1px 2px -1px rgb(255 255 255 / 15%),
    inset 1px 1px 3px 0px rgb(0 0 0 / 15%);
  border: 1px solid var(--input-border);
  border-radius: 5px;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: 375px;
  max-height: 400px;
  overflow: auto;
}

.ql-toolbar.ql-snow {
  border: 1px solid var(--input-border);
  margin-bottom: 10px;
  border-radius: 5px;
}

.ql-blank {
  &::before {
    color: var(--text) !important;
    // color: #ffffff !important;
  }
}

.wysiwyg {
  margin-bottom: 10px;
  //   .editor-outer {
  //     width: 100%;
  //     border-radius: 5px;
  //     padding-top: 0.5em;

  //     .toolbar {
  //       min-height: 40px;
  //       background-color: #ffffff;
  //       border-radius: 5px 5px 0 0;

  //       button {
  //         margin: 3px 2px;
  //         padding: 10px;
  //         width: 35px;
  //         height: 35px;
  //         background-color: #ffffff;
  //         border: none;
  //         transition: 0.4s;
  //         cursor: pointer;
  //         outline: none;

  //         &:hover {
  //           background-color: #cccccc;
  //         }
  //       }
  //       // end button
  //     }

  //     .editor {
  //       position: relative;

  //       .editor-inner {
  //         padding: 10px 16px;
  //         height: 375px;
  //         max-height: 373px;
  //         overflow: auto;
  //         position: relative;
  //         background: #1f2025;
  //         border: 1px solid transparent;
  //         border-radius: 0 0 8px 8px;
  //         box-shadow: 1px 1px 2px -1px rgba(255, 255, 255, 0.15),
  //           inset 1px 1px 3px 0px rgba(0, 0, 0, 0.15);
  //         outline: none;
  //         transition: 0.4s;
  //         color: #888;
  //         border-radius: 5px;

  //         outline: none;
  //         -webkit-appearance: none;
  //         transition: all 0.4s ease, visibility 0s;

  //         &:hover {
  //           border: 1px solid #4d4d4d;
  //         }
  //         &:focus {
  //           color: #fff;
  //           border: 1px solid #48bb8b;
  //           background-color: #1f1f1f;
  //         }
  //       }
  //       // end editor-inner

  //       .placeholder {
  //         position: absolute;
  //         top: 10px;
  //         left: 16px;
  //         color: #878787;
  //       }
  //       // end placeholder

  //       .hidden-editor {
  //         position: absolute;
  //         right: -1000em;
  //       }

  //       textarea {
  //         display: none;
  //       }
  //     }
  //     // end editor
  //   }
  //   // end editor-outer

  //   label {
  //     flex-basis: 100%;
  //     flex-wrap: wrap;
  //     margin-bottom: 0.5em;
  //     font-size: 16px;

  //     b {
  //       padding-left: 2px;
  //       color: @colors[input-required];
  //     }
  //   }

  &.error {
    label {
      color: #e60022;
    }

    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      background-color: #1a0306;

      &:focus {
        background-color: #47060f;
      }
    }
  }

  .error-msg {
    position: relative;
    width: 100%;
    top: -1px;
    margin: 0 6px;
    border: 1px solid #1a0306;
    border-top: 1px solid transparent;
    border-radius: 0 0 5px 5px;
    background: #4e2e33;
    transition: 0.4s;

    .error-msg-inner {
      padding: 2px 5px 4px 5px;

      .icon {
        padding-right: 5px;
      }
    }
  }
}
</style>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBold,
  faItalic,
  faUnderline,
  faTemperatureLow
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faBold, faItalic, faUnderline);

export default {
  name: "wysiwyg",
  components: {
    FontAwesomeIcon,
    quillEditor
  },
  props: {
    value: String,
    placeholder: String,
    label: String,
    required: Boolean,
    error: Boolean | String,
    createEvent: Boolean
  },
  data() {
    return {
      content: "<h2>I am Example</h2>",
      editorOption: {
        modules: {
          toolbar: [["bold", "italic", "underline"], ["clean"]]
        }
        // Some Quill options...
      },
      empty: true,
      text: this.value ? this.value : "",
      wasTextHandled: false
    };
  },
  watch: {
    value: function(val) {
      this.text = val;
    },
    text(val) {
      this.$emit("input", this.text);
    }
  },
  methods: {
    onEditorBlur(quill) {},
    onEditorFocus(quill) {},
    onEditorReady(quill) {},
    onEditorChange({ quill, html, text }) {
      this.text = html;
    },
    handleTxt(val) {
      console.log("1", val);
      if (!this.wasTextHandled) {
        this.text = val.innerHTML;
      }
      this.wasTextHandled = false;
    },
    hellothere(val) {
      console.log("2", val);
      this.text;
      let editor = this.$refs.editor;
      editor.innerHTML.length;
    },
    handlePaste(event) {
      console.log("3", val);
      let pasteData = (event.clipboardData || window.clipboardData).getData(
        "text"
      );
      this.text += pasteData;

      // console.log("paste", pasteData);
      event.preventDefault();
      this.wasTextHandled = true;
      // if(val.innerHTML === '') this.empty = true
      // else this.empty = false
      //old way to handle pasting
      let testEle = document.createElement("span");
      // let tmp = document.querySelector('#hidden-editor');
      // tmp.innerHTML = val.innerHTML;
      // this.text = tmp.textContent;
      //this.text = val.innerHTML
      let editor = this.$refs.editor;
      //editor.innerHTML = this.text;
      //insert empty div to move the text-cursor to last input
      editor.insertAdjacentElement("beforeend", testEle);
      let range = document.createRange();
      let sel = window.getSelection();
      range.setStartBefore(testEle);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      editor.focus();
      // this.$emit('input', this.text)
    },
    bolden() {
      // this.handleTxt(val)
      document.execCommand("bold");
    },
    italicize() {
      // this.handleTxt(val)
      document.execCommand("italic");
    },
    underline() {
      document.execCommand("underline");
    }
  },
  mounted() {
    if (this.text) {
      //paste method has been handled
      // let editor = this.$refs.editor;
      // let tmp = document.createElement("DIV");
      // //tmp.innerHTML = this.text;
      // //this.text = tmp.textContent;
      // editor.innerHTML = this.text;
    }
  },
  computed: {
    editor() {
      console.log("4", "what am i");
      return this.$refs.myQuillEditor.quill;
    }
  }
};
</script>
